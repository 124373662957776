<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const headers = [
  'Full-Stack Developer',
  'Problem Solver',
  'Photographer',
  'Team Player',
  'Continuous Learner',
];

const currentHeader = ref(headers[0]);
let headerIndex = 0;
let intervalId = null;

const imageVisible = ref(false);
const contentVisible = ref(false);

const changeHeader = () => {
  headerIndex = (headerIndex + 1) % headers.length;
  currentHeader.value = headers[headerIndex];
};

onMounted(() => {
  intervalId = setInterval(changeHeader, 2000);

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target.classList.contains('about-image')) {
            imageVisible.value = true;
            observer.unobserve(entry.target);
          }
          if (entry.target.classList.contains('about-content')) {
            contentVisible.value = true;
            observer.unobserve(entry.target);
          }
        }
      });
    },
  );

  const aboutImage = document.querySelector('.about-image');
  const aboutContent = document.querySelector('.about-content');

  if (aboutImage) observer.observe(aboutImage);
  if (aboutContent) observer.observe(aboutContent);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>


<template>
  <div class="about-section" :class="{ 'visible': isVisible }">
    <div class="about-background"></div>

    <div :class="['about-image', imageVisible ? 'visible' : '']">
      <img
        src="@/assets/naelia.png"
        alt="naelia-image"
      >
    </div>

    <div :class="['about-content', contentVisible ? 'visible' : '']">
      <p class="about-title">
        About
      </p>
      <h2 class="animated-header">
        {{ currentHeader }}
      </h2>
      <div class="about-text">
        <p>
            Hey there! I'm a junior fullstack web developer with a background in photography. My coding journey started at Chas Academy in 2021, where I spent two years studying full-stack development. Now, I'm passionate about building secure, visually appealing web solutions.
          </p>
          <p>
            During my 10 months at Enspirit, I gained valuable experience in both frontend and backend development. I discovered a love for testing and enjoyed learning about working closely with UX teams, especially when it comes to accessibility and user experience. I'm excited to dive deeper into these areas and continue growing my skills.
          </p>
          <p>
            <strong>My Approach:</strong> I enjoy solving problems and learning new technologies to enhance my development skills. I am highly motivated, diligent, and never give up when faced with challenges. I thrive in team environments but also work well independently.
          </p>
          <p>
            Want to collaborate? Whether you need a fresh new website, want to share ideas, or are considering hiring me, let’s get in touch!
          </p>
          <p>
            You can check out my
            <a
              href="https://www.linkedin.com/in/naeliasalas/"
              target="_blank"
            >LinkedIn profile</a> or visit my
            <a
              href="your-github-url"
              target="_blank"
            >GitHub</a>.
          </p>
        <p>
          I am proficient in communication, love spreading positive energy, and enjoy sharing laughter.
        </p>
      </div>
      <div class="about-btn">
      <a download="NaeliaSalas.pdf" href="/NaeliaSalas.pdf">
     <button class="cv-button"> Download full CV
    </button>
  </a>
</div>
    </div>
  </div>
</template>


<style scoped>
.about-section {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  /* height: 80vh; */
  margin: 0 auto;
  max-width: 1600px;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.about-image {
  position: relative;
  display: inline-block;
  align-self: flex-start;
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  /* z-index: 99; */
  margin-right: 90px;
}

.about-image img {
  border-radius: 10px;
  height: auto;
  max-width: 100%;
}

.about-image.visible {
  opacity: 1;
  transform: translateX(0);
}

.about-content {
  flex: 1;
  max-width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.about-content.visible {
  opacity: 1;
  transform: translateX(0);
}

.about-background {
  position: absolute;
  top: 50%;
  left: -15%;
  width: 55%;
  height: 100%;
  background: rgba(0, 123, 255, 0.3);
  transform: translateY(-60%) scale(1.0);
  border-radius: 50%;
  filter: blur(80px);
}

.about-title {
  font-size: 34px;
  color: #00afea;
  margin-bottom: 10px;
}

.animated-header {
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
  color: white;
  text-align: left;
}

.about-text a {
  color: #00afea;
  text-decoration: none;
}

.about-text a:hover {
  text-decoration: underline;
}

.cv-button {
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 40px;
  transition: background 0.3s;
  background: linear-gradient(135deg, #00afea 0%, #0044cc 100%);
  color: #fff;
  border: none;
}

.cv-button:hover {
  background: linear-gradient(135deg, #008fcc 0%, #003399 100%);
}

@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-bottom: 50px;
  }

  .about-image {
    margin-right: 0;
    margin-bottom: 20px;
    align-self: center;
    transform: translateX(0);
    opacity: 1;
  }

  .about-image.visible {
    opacity: 1;
    transform: translateX(0);
  }

  .about-image img {
    width: 80%;
    max-width: 300px;
    height: auto;
  }

  .about-content {
    max-width: 100%;
    align-items: center;
    text-align: center;
    transform: translateX(0);
    opacity: 1;
  }

  .about-content.visible {
    opacity: 1;
    transform: translateX(0);
  }

  .about-text p {
    text-align: center;
  }


}
</style>


